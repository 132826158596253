import clsx from 'clsx';
import type { InputHTMLAttributes } from 'react';
import { forwardRef } from 'react';
import Label from '~/components/Label';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label: string;
  className?: string;
}

export default forwardRef<HTMLInputElement, InputProps>(
  ({ id, label, className, ...rest }, ref) => (
    <div className={clsx('flex flex-col', className)}>
      <Label htmlFor={id}>{label}</Label>
      <input
        id={id}
        ref={ref}
        {...rest}
        className="ring-primary mt-1 rounded border-2 border-gray-300 py-2 px-3 outline-0 ring-offset-2 hover:border-gray-500 focus:ring-2"
      />
    </div>
  ),
);
